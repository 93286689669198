import { NameConstants } from '~/utils/constants';
import { Link } from '@app/_components/link';
import { Image } from '@styled-system/jsx';
import logo from './assets/logo.svg';

export const HeaderLogo = () => (
  <Link href="/">
    <Image
      src={logo}
      loading="lazy"
      alt={NameConstants.SITE_NAME_WITH_EN}
      width={{
        base: '126px',
        lg: '161px',
      }}
      minWidth={{
        base: '126px',
        lg: '161px',
      }}
      cursor="pointer"
      _hover={{
        opacity: 0.8,
      }}
    />
  </Link>
);
