import { FiSearch } from 'react-icons/fi';
import { Color } from '~/utils/styles/enums';
import { HStack, Paragraph } from '@styled-system/jsx';

type Props = {
  onClick?: () => void;
};

export const HeaderSearch = ({ onClick }: Props) => (
  <HStack
    bg="strong_bg"
    w="600px"
    h="40px"
    p="8px"
    borderRadius="8px"
    alignItems="center"
    display={{
      base: 'none',
      lg: 'flex',
    }}
    maxW="600px"
    flexGrow={1}
    cursor="pointer"
    onClick={onClick}
  >
    <FiSearch color={Color.ACCENT_1000} size={24} />
    <Paragraph
      color="accent.500"
      fontSize="size_14"
      fontWeight="bold"
      width="100%"
      pt="2px"
    >
      タイトル、作家名、タグで検索
    </Paragraph>
  </HStack>
);
