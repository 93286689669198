import { useMemo } from 'react';
import { routes } from '~/utils/routes';
import { Link } from '@app/_components/link';
import { css } from '@styled-system/css';
import { Box, Image, Center } from '@styled-system/jsx';

import notificationIcon from './assets/notification-icon.svg';

type Props = {
  onClick?: () => void;
  notificationCount?: number;
};

export const HeaderNotificationsWithBadge = ({
  onClick,
  notificationCount,
}: Props) => {
  const notificationCountText = useMemo(() => {
    if (!notificationCount) return;

    return notificationCount > 99 ? '99+' : String(notificationCount);
  }, [notificationCount]);

  return (
    <Link
      href={routes.notifications.url()}
      className={css({ hideBelow: 'lg', width: '30px' })}
    >
      <Box
        cursor="pointer"
        _hover={{
          opacity: 0.8,
        }}
        position="relative"
        onClick={onClick}
      >
        <Image
          src={notificationIcon}
          alt="通知"
          w="24px"
          h="24px"
          objectFit="fill"
          minWidth="24px"
        />
        {notificationCountText && (
          <Center
            bgColor="red"
            color="white"
            fontSize="size_10"
            fontWeight="bold"
            borderRadius="50%"
            h="18px"
            w="18px"
            position="absolute"
            left="14px"
            bottom="12px"
            pt="2px"
            letterSpacing={notificationCountText.length > 2 ? '-1px' : '0'}
            textAlign="center"
            animation="fadein 400ms ease-out"
          >
            {notificationCountText}
          </Center>
        )}
      </Box>
    </Link>
  );
};
