import { RiPencilFill } from 'react-icons/ri';
import { Color } from '~/utils/styles/enums';
import { HStack, Paragraph } from '@styled-system/jsx';

type Props = {
  onClick?: () => void;
};

export const HeaderWriteStoryButton = ({ onClick }: Props) => (
  <HStack
    cursor="pointer"
    _hover={{
      opacity: 0.8,
    }}
    display={{
      base: 'none',
      lg: 'flex',
    }}
    height={{
      base: '30px',
      lg: '40px',
    }}
    color="white"
    backgroundColor="tint"
    padding="6px 14px"
    borderRadius="20px"
    onClick={onClick}
    minWidth="170px"
  >
    <RiPencilFill color={Color.WHITE} size={20} />
    <Paragraph fontSize="size_14" fontWeight={700} userSelect="none">
      ストーリーを書く
    </Paragraph>
  </HStack>
);
