'use client';
import { Dialog } from '@ark-ui/react';
import { useCallback } from 'react';

import { styled } from '@styled-system/jsx';
import { HeaderMenuContent } from './header-menu-content';

type Props = {
  isOpen: boolean;
  onClickMyStories?: () => void;
  onClose?: () => void;
  onClickLogout?: () => void;
  isAnonymous?: boolean;
  isVip?: boolean;
};

export const DialogMenu = ({
  isOpen,
  onClose,
  onClickMyStories,
  onClickLogout,
  isAnonymous,
  isVip,
}: Props) => {
  const handleOnOpenChange = useCallback(
    ({ open }: { open: boolean }) => {
      if (!open) {
        onClose?.();
      }
    },
    [onClose]
  );

  return (
    <Dialog.Root open={isOpen} onOpenChange={handleOnOpenChange}>
      <Positioner>
        <Content>
          <HeaderMenuContent
            onClickMyStories={onClickMyStories}
            onClickLogout={onClickLogout}
            isAnonymous={isAnonymous}
            isVip={isVip}
          />
        </Content>
      </Positioner>
    </Dialog.Root>
  );
};

const Positioner = styled(Dialog.Positioner, {
  base: {
    alignItems: 'center',
    display: 'flex',
    height: 'calc(100dvh - 54px - 93px)', // 54px: header height, 95px: bottom navigation height
    justifyContent: 'center',
    position: 'fixed',
    top: '93px',
    width: '100%',
    zIndex: 'drawer',
    left: 0,
  },
});

const Content = styled(Dialog.Content, {
  base: {
    background: 'white',
    boxShadow: 'lg',
    height: '100%',
    width: '100%',
    overflow: 'scroll',
    _open: {
      animation: 'fadein 150ms ease-out',
    },
    _closed: {
      animation: 'fadeout 150ms ease-out',
    },
  },
});
