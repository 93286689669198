import { Button } from '@app/_components/button';

type Props = {
  onClickLogin: () => void;
  onClickRegister: () => void;
};

export const HeaderLoginRegisterButtons = ({
  onClickLogin,
  onClickRegister,
}: Props) => (
  <>
    <Button
      onClick={() => onClickLogin()}
      size="medium"
      type="outline"
      animation="fadein 400ms ease-out"
      px="2.5"
      lineHeight="1"
      height={{
        base: '30px',
        lg: '40px',
      }}
    >
      ログイン
    </Button>
    <Button
      onClick={() => onClickRegister()}
      px="2.5"
      size="medium"
      type="whiteOverBlack"
      animation="fadein 400ms ease-out"
      lineHeight="1"
      height={{
        base: '30px',
        lg: '40px',
      }}
    >
      無料登録
    </Button>
  </>
);
