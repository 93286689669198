import { routes } from '~/utils/routes';
import { Link } from '@app/_components/link';
import { css } from '@styled-system/css';
import { Image } from '@styled-system/jsx';
import userPlaceholderIcon from './assets/user-placeholder.svg';
import type { User } from './';

type Props = {
  user?: User;
};

export const HeaderUserThumbnail = ({ user }: Props) => (
  <Link
    className={css({ width: '24px', minWidth: '24px' })}
    href={routes.myPage.url()}
  >
    <Image
      src={user?.thumbnailUrl ?? userPlaceholderIcon}
      alt="ユーザー"
      w="24px"
      h="24px"
      minWidth="24px"
      objectFit="cover"
      borderRadius="12px"
      animation="fadein 400ms ease-out"
    />
  </Link>
);
