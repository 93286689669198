import { cva } from '@styled-system/css';

type Props = {
  isShowClose: boolean;
  onClick?: () => void;
};

export const HamburgerCloseIcon = ({ isShowClose, onClick }: Props) => {
  return (
    <div
      onClick={onClick}
      className={wrapperStyle({ state: isShowClose ? 'close' : 'hamburger' })}
    >
      <div
        className={barStyle({ state: isShowClose ? 'close' : 'hamburger' })}
      />
      <div
        className={barStyle({ state: isShowClose ? 'secondBar' : 'hamburger' })}
      />
      <div
        className={barStyle({ state: isShowClose ? 'close' : 'hamburger' })}
      />
    </div>
  );
};

const wrapperStyle = cva({
  base: {
    animation: 'fadein 400ms ease-out',
    _hover: {
      cursor: 'pointer',
    },
    alignItems: 'center',
    justifyContent: 'center',
  },
  variants: {
    state: {
      hamburger: {},
      close: {},
    },
  },
});

const barStyle = cva({
  base: {
    margin: '4px',
    width: '20px',
    height: '2px',
    background: '#000',
    borderRadius: '1.5px',
    transition: 'margin 0.2s, transform 0.2s',
  },
  variants: {
    state: {
      hamburger: {},
      close: {
        _firstOfType: {
          transform: 'translateY(6px) rotate(45deg)',
        },
        _lastOfType: {
          transform: 'translateY(-6px) rotate(-45deg)',
        },
        _hover: {
          cursor: 'pointer',
        },
      },
      secondBar: {
        opacity: 0,
      },
    },
  },
});
