'use client';
import { usePathname } from 'next/navigation';
import { memo, useEffect } from 'react';
import type { UserOverview } from '~/common/types/user-overview';
import { PopoverMenu } from '@app/_components/header-menu';
import { css } from '@styled-system/css';
import { HStack, Box } from '@styled-system/jsx';

import {
  HeaderSearch,
  HamburgerCloseIcon,
  HeaderNotificationsWithBadge,
  HeaderWriteStoryButton,
  HeaderLogo,
  HeaderUserThumbnail,
  HeaderLoginRegisterButtons,
} from './';

export const HEADER_PC_HEIGHT = 72;
export const HEADER_SP_HEIGHT = 50;

export type User = {
  isAnonymous: boolean;
} & Pick<
  UserOverview,
  'id' | 'name' | 'thumbnailUrl' | 'isVip' | 'isOfficialWriter'
>;

type Props = {
  isMobile?: boolean;
  // ログインセッションがあるかどうか
  isSessionReady: boolean;
  // Logged out
  isAnonymous?: boolean;
  // ログインしているユーザー情報
  user?: User;
  // 通知の件数
  notificationCount?: number;
  // 検索をクリック
  onClickSearch?: () => void;
  // クリックのハンドリング
  onClickWrite?: () => void;
  // 通知をクリック
  onClickNotification?: () => void;
  // 自分のストーリーをクリック
  onClickMyStories?: () => void;
  // ハンバーガーメニューをクリック
  onOpenHeaderMenu?: () => void;
  onCloseHeaderMenu?: () => void;
  // ログインをクリック
  onClickLogin: () => void;
  // ログアウトをクリック
  onClickLogout: () => void;
  // ハンバーガーメニューが開いているかどうか
  isHeaderMenuOpen?: boolean;
  // Registration button click
  onClickRegister: () => void;
};

const _Header = ({
  isMobile,
  isSessionReady,
  isAnonymous,
  user,
  notificationCount,
  isHeaderMenuOpen,
  onClickSearch,
  onClickWrite,
  onClickNotification,
  onClickMyStories,
  onOpenHeaderMenu,
  onCloseHeaderMenu,
  onClickLogout,
  onClickLogin,
  onClickRegister,
}: Props) => {
  const pathname = usePathname();

  useEffect(() => {
    onCloseHeaderMenu?.();
  }, [onCloseHeaderMenu, pathname]);
  return (
    <header
      className={css({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        bg: 'white',
        width: '100%',
        pl: {
          base: '12px',
          lg: '40px',
        },
        pr: {
          base: '12px',
          lg: '30px',
        },
        height: {
          base: `${HEADER_SP_HEIGHT}px`,
          lg: `${HEADER_PC_HEIGHT}px`,
        },
        borderBottom: '0.5px solid #D7D8DA',
      })}
    >
      <HStack
        justifyContent="space-between"
        maxW="1220px"
        margin={{
          base: '0',
          lg: 'auto',
        }}
        w="100%"
      >
        {/* Logo */}
        <HeaderLogo />

        {/* PC Search */}
        <HeaderSearch onClick={onClickSearch} />

        {/* Write  Story*/}
        <HeaderWriteStoryButton onClick={onClickWrite} />

        {/* Reserve space for elements that depends */}
        {/* on session so that the layout doesn't jump */}
        <HStack
          width={{ lg: '200px', base: '100%' }}
          maxWidth={{ lg: '200px', base: '100%' }}
          gap="1"
          justifyContent="flex-end"
          justifySelf="flex-end"
        >
          {isSessionReady && (
            <>
              {/* PC ELEMENTS */}
              {/* login-register buttons, notifications icon and user thumbnail */}
              <HStack hideBelow="lg" alignItems="center">
                {/* Login/Register Buttons */}
                {isAnonymous && (
                  <HeaderLoginRegisterButtons
                    onClickLogin={onClickLogin}
                    onClickRegister={onClickRegister}
                  />
                )}

                {/* PC Notifications and user thumbnail  */}
                {!isAnonymous && (
                  <HStack>
                    <HeaderNotificationsWithBadge
                      onClick={onClickNotification}
                      notificationCount={notificationCount}
                    />
                    <HeaderUserThumbnail user={user} />
                  </HStack>
                )}
              </HStack>

              {/* MOBILE ELEMENTS */}
              {/* login-register buttons, user thumbnail */}
              <HStack hideFrom="lg" justifyContent="flex-end" gap="2">
                {isAnonymous && (
                  <HeaderLoginRegisterButtons
                    onClickLogin={onClickLogin}
                    onClickRegister={onClickRegister}
                  />
                )}
                {/* User thumbnail */}
                {!isAnonymous && <HeaderUserThumbnail user={user} />}
              </HStack>
            </>
          )}
        </HStack>

        {/* Hamburger Menu */}
        {isMobile === undefined ? (
          <Box width="28px" />
        ) : (
          <>
            {isMobile ? (
              <HamburgerCloseIcon
                isShowClose={isHeaderMenuOpen || false}
                onClick={onOpenHeaderMenu}
              />
            ) : (
              <PopoverMenu
                onOpen={onOpenHeaderMenu}
                isOpen={isHeaderMenuOpen || false}
                onClose={onCloseHeaderMenu}
                onClickMyStories={onClickMyStories}
                onClickLogout={onClickLogout}
                isAnonymous={isAnonymous}
                isVip={user?.isVip}
              />
            )}
          </>
        )}
      </HStack>
    </header>
  );
};
export const Header = memo(_Header);
